<template>
    <div class="recharge">

        <div class="h5header">
            <img src="../../public/images/new/backicon.png" alt="" class="return" @click="$router.go(-1)" />
            <div class="name flexcenter">{{ $t('asset.desc114') }}</div>
            <div class="lang">
                <img src="../../public/images/new/recordicon.png" alt=""
                    @click="$router.push('/third/record?id=' + $route.query.coinId)" />
            </div>
        </div>
        <div class="wrap">
            <div class="wrap-title"><span @click="$router.go(-1)">{{ $t('asset.desc52') }}</span> > <span>{{
                $t('asset.desc114')
            }}</span></div>
            <div class="wrap-con flex">
                <div class="wrap-left">
                    <div class="step flex">
                        <div class="step-line">
                            <img src="../../public/images/new/dui.png" alt="" />
                        </div>
                        <div class="step-info">
                            <div class="info-name">{{ $t('asset.desc115') }}</div>
                            <div class="info-select flex">
                                <img :src="coinInfo.coinIcon" alt="" />{{ coinInfo.coinName }}


                            </div>
                        </div>
                    </div>
                    <div class="step flex">
                        <div class="step-line"><img src="../../public/images/new/dui.png" alt="" /></div>
                        <div class="step-info">
                            <div class="info-name">{{ $t('asset.desc116') }}</div>
                            <div class="info-nav flex">
                                {{ $t('asset.desc117', { n1: coinInfo.coinName }) }}
                            </div>

                        </div>
                    </div>
                    <div class="step flex">
                        <div class="step-line">3</div>
                        <div class="step-info">
                            <div class="info-name">{{ $t('asset.desc118') }}</div>
                            <div class="userid flex">
                                <div class="userid-info">
                                    UID：{{ userInfo.userId }}
                                </div>
                                <div class="userid-btn flex" @click="copy($event, userInfo.userId)">
                                    <img src="../../public/images/new/copy.png" alt="" /> <p>{{ $t('asset.desc119') }}</p>
                                </div>
                            </div>
                            <div class="info-nav flex">
                                {{ $t('asset.desc120', { n1: coinInfo.coinName }) }}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="wrap-right">
                    <div class="right-name">{{ $t('asset.desc114') }}</div>
                    <div class="right-tips">{{ $t('asset.desc121', { n1: coinInfo.coinName }) }}</div>
                    <div class="right-tips">{{ $t('asset.desc122', { n1: coinInfo.coinName }) }}</div>
                    <div class="right-tips">{{ $t('asset.desc137') }}</div>
                    <div class="right-tips">{{ $t('asset.desc123') }}</div>


                </div>
            </div>
            <div class="wrap-record">
                <div class="record-title flex">
                    <div>{{ $t('asset.desc124') }}</div>
                </div>
                <div class="record-time">
                    <div class="select-time flex">
                        <el-date-picker @change="dateSelect" v-model="value1" type="daterange"
                            :range-separator="$t('record.desc2')" :start-placeholder="$t('record.desc1')"
                            :end-placeholder="$t('record.desc3')">
                        </el-date-picker>

                    </div>
                </div>
                <div class="record-table flex">
                    <div>{{ $t('asset.desc125') }}</div>
                    <div>{{ $t('asset.desc126') }}</div>
                    <div>{{ $t('asset.desc127') }}</div>
                    <div>{{ $t('asset.desc129') }}</div>
                    <div>{{ $t('asset.desc128') }}</div>
                    
                    <!-- <div>{{ $t('asset.desc130') }}</div> -->
                    <div>{{ $t('asset.desc131') }}</div>
                    <div>{{ $t('asset.desc132') }}</div>
                    <div>{{ $t('asset.desc133') }}</div>
                </div>
                <div class="record-tbody">
                    <div class="tobdy-list flex" v-for="item in list" :key="item.id">
                        <div class="flex">{{ dealAddress(item.sn) }} <img src="../../public/images/new/copy.png" alt=""
                                @click="copy($event, item.sn)" class="img1" /></div>
                        <div>{{ common.formatDate(item.transferTime) }}</div>
                        <div>{{ $t('asset.desc134') }}</div>
                        <div class="flex">HYPERCOMUNITY </div>
                        <div class="flex">{{ item.resourceUserId }} <img src="../../public/images/new/copy.png" alt=""
                                @click="copy($event, item.resourceUserId)" class="img1" /></div>
                         
                       <!-- <div class="flex">{{ dealAddress1(item.resourceEmail) }} <img src="../../public/images/new/copy.png"
                                alt="" @click="copy($event, item.resourceEmail)" class="img1" /></div> -->
                        <div class="flex"><img :src="item.coinIcon" alt="" class="img2" />{{ item.coinName }}</div>
                        <div>{{ item.amount }}</div>
                        <div>{{ $t('record.desc21') }}</div>
                    </div>
                    <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
                        <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                            :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent"> </el-pagination>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="" />
                    <span>{{ $t("record.desc13") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import common from '@/utils/common';
import Clipboard from '@/utils/libs/clipboard'
export default {

    data() {
        return {
            coinInfo: {},
            userInfo: {},
            value1: '',
            page: 1,
            startTime: '',
            endTime: '',
            totals: 0,
            showNull: false,
            list:[]
        }
    },

    mounted() {
        // this.userInfo = this.$store.state.userData;
        this.$post(this.URL.member.info,{}).then( res =>{
            if(res.code==0){
                this.userInfo=res.data;
                
            }
        })
        this.$post(this.URL.assets.view, {
            relationCoinId: this.$route.query.coinId,
            accountType: this.$route.query.type
        }).then(res => {
            if (res.code == 0) {
                this.coinInfo = res.data;
            } else {
                this.$message.error(res.message)
            }
        })
        this.getlist();
    },
    methods: {
        handlerCurrent(val) {
            this.page = val;
            this.getlist();
        },
        dealAddress(str) {
            if (str && str.length > 12) {
                return str.substring(0, 6) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },
        dealAddress1(str) {
            if (str && str.length > 12) {
                return str.substring(0, 2) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },

        copy(e, text) {
            // @click="copy($event,googleRandom.secretKey)"
            let that = this;
            text = text.toString()
            Clipboard(e, text).then((res) => {
                that.$message.success(this.$t('planet.desc61'))
            });
        },
        dateSelect(arr) {
            if (arr != null) {
                this.startTime = this.newDate(arr[0]);
                this.endTime = this.newDate(arr[1]);
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.page=1;
            this.getlist();
        },
        getlist() {
            this.$post(this.URL.assets.thirdRecord, {
                page: this.page,
                pageSize: 10,
                beginTime: this.startTime,
                endTime: this.endTime
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list;
                    this.totals = parseFloat(res.data.page.count);
                    if (this.totals == 0) {
                        this.showNull = true;
                    }
                }
            })
        },
        newDate(time) {
            // 根据时间查询
            var date = new Date(time);
            let datetime=date.getTime();
            return datetime
        },
    }
}
</script>
  
<style lang="less" scoped>
.recharge {
    padding: 116px 0 69px;
    width: 100%;
    min-height: 100vh;
    background: url('../../public/images/new/landbg.png') center no-repeat;
    background-size: 100% 100%;

    .wrap {
        width: 1240px;
        margin: 0 auto;

        .wrap-title {
            color: #A0A0A0;
            font-size: 13px;

            span {
                &:first-child {
                    cursor: pointer;
                }

                &:last-child {
                    color: #fff;
                }
            }
        }

        .wrap-con {
            margin-top: 32px;
            justify-content: space-between;

            .wrap-left {
                flex: 0 0 514px;

                .step {
                    position: relative;
                    padding-bottom: 32px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 22px;
                        left: 10px;
                        width: 1px;
                        height: 100%;
                        background: #C9FA5B;
                    }

                    &:last-child {
                        padding-bottom: 0;

                        &::after {
                            width: 0;
                        }

                        .step-line {
                            height: 22px;
                            background: #C9FA5B;
                            border-radius: 50%;
                            font-size: 14px;
                            color: #000;
                            text-align: center;
                            line-height: 22px;
                        }
                    }

                    .step-line {
                        flex: 0 0 22px;
                        margin-right: 12px;

                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    .step-info {
                        flex: 0 0 480px;

                        .info-name {
                            font-size: 18px;
                            color: #FFFFFF;
                            line-height: 18px;
                            margin-bottom: 12px;
                        }

                        .info-nav {
                            font-size: 18px;
                            color: #C9FA5B;
                            line-height: 24px;
                        }

                        .info-tips {
                            font-size: 18px;
                            color: #C9FA5B;
                        }



                        .info-select {
                            position: relative;
                            padding: 12px 16px;
                            border: 1px solid rgba(255, 255, 255, 0.20);
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #FFFFFF;
                            line-height: 24px;

                            img {
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                                border-radius: 50%;
                            }

                        }

                        .userid {
                            margin-bottom: 16px;
                            padding: 16px;
                            border: 1px solid rgba(255, 255, 255, 0.20);
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #FFFFFF;
                            line-height: 16px;

                            .userid-info {
                                flex: 1;

                                overflow: hidden;
                            }

                            .userid-btn {
                                cursor: pointer;

                                img {
                                    width: 12px;
                                    height: 12px;
                                    margin: 2px 4px 0 0;
                                }
                            }
                        }
                    }
                }
            }

            .wrap-right {
                flex: 0 0 420px;
                padding: 24px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                height: fit-content;

                .right-name {
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }

                .right-tips {
                    font-size: 14px;
                    color: #A8A8A8;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .record-title {
            margin: 40px 0 16px;
            justify-content: space-between;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 23px;

        }

        .record-time {
            margin-bottom: 24px;

            .select-time {
                width: 320px;

                ::v-deep .el-date-editor {
                    height: 40px;
                    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
                    background-color: rgba(255, 255, 255, 0);
                    border: 1px solid rgba(255, 255, 255, 0.2) !important;

                    .el-range-input {
                        color: #fff;
                    }

                    .el-range-separator {
                        color: #fff;
                    }
                }
            }
        }

        .record-table {
            padding: 20px 24px;
            background: #181818;

            div {
                flex: 0 0 189px;
                font-size: 13px;
                color: rgba(255, 255, 255, .6);

                &:nth-child(2) {
                    flex: 0 0 186px;
                }

                &:nth-child(3) {
                    flex: 0 0 232px;
                }

                &:nth-child(4),
                &:nth-child(5) {
                    flex: 0 0 138px;
                }

            

                &:nth-child(6) {
                    flex: 0 0 100px;
                }

                &:nth-child(7) {
                    flex: 1;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                }
            }
        }

        .record-tbody {
            .tobdy-list {
                padding: 20px 24px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                div {
                    flex: 0 0 189px;
                    font-size: 13px;
                    color: #FFFFFF;
                    line-height: 18px;

                    &:nth-child(2) {
                    flex: 0 0 186px;
                }

                &:nth-child(3) {
                    flex: 0 0 232px;
                }

                &:nth-child(4),
                &:nth-child(5) {
                    flex: 0 0 138px;
                }

            

                &:nth-child(6) {
                    flex: 0 0 100px;
                }

                &:nth-child(7) {
                    flex: 1;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                }

                    .img1 {
                        width: 12px;
                        height: 12px;
                        margin: 3px 0 0 4px;
                        cursor: pointer;
                    }

                    .img2 {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                        border-radius: 50%;
                    }
                }
            }

            .planet-page {
                margin-top: 40px;
                padding: 0;

                ::v-deep .el-pagination {
                    button {
                        background-color: rgba(255, 255, 255, 0);
                        color: #666666;
                        font-size: 14px;
                    }

                    .el-pager {
                        li {
                            background: rgba(255, 255, 255, 0);
                            font-size: 14px;
                            color: #666666;
                        }

                        .is-active {
                            background: #C9FA5B !important;
                            border-radius: 4px !important;
                            color: #000000 !important;
                        }
                    }

                }
            }
        }

        .no-data {
            width: 100%;
            padding: 130px 0;

            img {
                display: block;
                width: 120px;
                height: 120px;
                margin: 0 auto 16px;
            }

            span {
                display: block;
                margin: 0 auto;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #a1a1a1;
            }
        }
    }
}

.h5header {
    display: none;
}

::v-deep .el-dropdown {
    width: 100%;
    border: 0 !important;

    .el-dropdown-link {
        width: 100%;
        border: 0 !important;

        .icon-name {
            width: 100%;
            font-size: 16px;
            color: #fff;
            line-height: 24px;
            border: 0px solid #eee !important;

            img {
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}

.coin-info {
    width: 446px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;

    &:hover {
        background: #292929 !important;
    }

    img {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.select-search {
    padding: 0 16px 6px;
    width: 446px;

    .name {
        padding-top: 20px;
        font-size: 18px;
        color: #FFFFFF;
    }

    .input {
        margin-top: 20px;
        width: 100%;
        padding: 13px 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;

        img {
            flex: 0 0 18px;
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }

        input {
            flex: 1;
            height: 18px;
            font-size: 16px;
            color: #fff;

            &::placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}

@media (max-width:1200px) {
    .recharge {
        padding: 88px 16px 70px;
        min-height:fit-content;

        .wrap {
            width: 100%;

            .wrap-con {
                margin-top: 16px;
                flex-wrap: wrap;

                .wrap-left {
                    flex: 0 0 100%;
                }

                .wrap-right {
                    margin-top: 20px;
                    padding: 14px;
                    border: 0;
                }
            }
            .wrap-record{
                display: none;
            }
        }
    }
}

@media (max-width:767px) {

    .coin-info,
    .select-search {
        width: 100%;
    }

    .h5header {
        position: relative;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 44px;
        background: #000;
        z-index: 888;
        display: block;

        .return {
            position: absolute;
            top: 50%;
            left: 16px;
            width: 14px;
            height: 22px;
            transform: translateY(-50%);
        }

        .name {
            width: 80%;
            margin: 0 auto;
            font-size: 16px;
            color: #fff;
            height: 44px;
        }

        .lang {
            position: absolute;
            top: 50%;
            right: 16px;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);

            img {
                width: 20px;
                height: 20px;
            }
        }

    }

    .recharge {
        padding: 56px 16px;

        .wrap {
            .wrap-title {
                display: none;
            }

            .wrap-con {
                .wrap-left {
                    .step {
                        padding-bottom: 24px;

                        .step-info {
                            flex: 1;

                            .info-name {
                                font-size: 16px;
                                margin-bottom: 16px;
                            }

                            .info-select {
                             padding: 10px 12px;
                            }

                            .info-nav {
                                font-size: 14px;
                                line-height: 18px;
                            }

                            .info-tips {
                                font-size: 16px;
                            }

                            .userid{
                                padding: 10px 12px;
                                .userid-info{
                                    font-size: 15px;
                                }
                                .userid-btn{
                                    p{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .wrap-right {
                    flex: 0 0 100%;
                    padding: 14px;
                    background: #181818;
                    border-radius: 8px;

                    .right-name {
                        margin-bottom: 16px;
                        font-size: 16px;
                    }

                    .right-tips {
                        font-size: 13px;
                    }
                }
            }

            .wrap-record {
                display: none;
            }
        }
    }
}
</style>